import Spinner from "@modules/common/icons/spinner"
import clsx from "clsx"
import React from "react"

type ButtonProps = {
  isLoading?: boolean
  disabled?: boolean
  variant?: "primary" | "secondary"
} & React.ButtonHTMLAttributes<HTMLButtonElement>

const Button = ({
  children,
  className,
  isLoading = false,
  variant = "primary",
  disabled = false,
  ...props
}: ButtonProps) => {
  return (
    <button
      {...props}
      disabled={disabled}
      className={clsx(
        "w-full uppercase flex items-center justify-center min-h-[50px] px-5 py-[10px] text-small-regular transition-colors rounded-xl disabled:opacity-50",
        {
          "text-neutral-80 bg-background-10 hover:bg-background-60 hover:text-neutral-99 disabled:text-neutral-90 disabled:hover:bg-neutral-10":
            variant === "primary",
          "text-neutral-99 transition-all duration-500 bg-gradient-to-r from-primary-50 to-primary-70 hover:from-primary-40 hover:to-primary-60 disabled:hover:bg-background-10":
            variant === "secondary",
        },
        className
      )}
    >
      {isLoading ? <Spinner /> : children}
    </button>
  )
}

export default Button
