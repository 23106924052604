import { Popover, Transition } from "@headlessui/react"
import { useCartDropdown } from "@lib/context/cart-dropdown-context"
import { useStore } from "@lib/context/store-context"
import useEnrichedLineItems from "@lib/hooks/use-enrich-line-items"
import Button from "@modules/common/components/button"
import LineItemOptions from "@modules/common/components/line-item-options"
import LineItemPrice from "@modules/common/components/line-item-price"
import Trash from "@modules/common/icons/trash"
import Cart from "@modules/common/icons/cart"
import Thumbnail from "@modules/products/components/thumbnail"
import { formatAmount, useCart } from "medusa-react"
import Link from "next/link"
import { Fragment } from "react"

const CartDropdown = () => {
  const { cart, totalItems } = useCart()
  const items = useEnrichedLineItems()
  const { deleteItem } = useStore()
  const { state, open, close } = useCartDropdown()

  return (
    <div className="h-full z-50" onMouseEnter={open} onMouseLeave={close}>
      <Popover className="h-full justify-center">
        <Link href="/cart" passHref>
          <Popover.Button className="h-full relative float-left">
            <span className="float-right print:hidden sm:px-1 text-xs rounded-full">{totalItems}</span>
            <Cart size={35} />
          </Popover.Button>
        </Link>
        <Transition
          show={state}
          as={Fragment}
          enter="transition ease-out duration-200"
          enterFrom="opacity-0 translate-y-1"
          enterTo="opacity-100 translate-y-0"
          leave="transition ease-in duration-150"
          leaveFrom="opacity-100 translate-y-0"
          leaveTo="opacity-0 translate-y-1"
        >
          <Popover.Panel
            static
            className="small:block w-full absolute top-full inset-x-0 right-0 left-0 small:left-auto bg-background-60 bg-opacity-70 rounded-b-xl backdrop-blur-md small:w-[382px] text-neutral-99"
          >
            <div className="p-4 flex items-center justify-center">
              <h3 className="text-large-semi">Carrito</h3>
            </div>
            {cart && items?.length ? (
              <>
                <div className="overflow-y-scroll max-h-[402px] px-3 grid grid-cols-1 gap-y-8 no-scrollbar">
                  {items
                    .sort((a, b) => {
                      return a.created_at > b.created_at ? -1 : 1
                    })
                    .map((item) => (
                      <div
                        className="grid xsmall:grid-cols-[110px_1fr] gap-x-1"
                        key={item.id}
                      >
                        <div className="w-[110px]  mx-auto">
                          <Thumbnail thumbnail={item.thumbnail} size="full" />
                        </div>
                        <div className="flex flex-col justify-between flex-1 text-neutral-99">
                          <div className="flex flex-col flex-1">
                            <div className="flex items-start justify-between">
                              <div>
                                <h3 className="text-base-regular overflow-ellipsis overflow-hidden whitespace-nowrap mr-4 w-[130px]">
                                  <Link
                                    href={`/products/${item.variant.product.handle}`}
                                  >
                                    {item.title}
                                  </Link>
                                </h3>
                                <LineItemOptions variant={item.variant} />
                                <span>Cantidad: {item.quantity}</span>
                              </div>
                              <div className="flex justify-end">
                                <LineItemPrice
                                  region={cart.region}
                                  item={item}
                                  style="tight"
                                />
                              </div>
                            </div>
                          </div>
                          <div className="flex items-end justify-between text-small-regular flex-1">
                            <div>
                              <button
                                className="flex items-center gap-x-1 text-neutral-10"
                                onClick={() => deleteItem(item.id)}
                              >
                                <Trash size={14} />
                                <span>Eliminar</span>
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                </div>
                <div className="p-4 flex flex-col gap-y-4 text-small-regular">
                  <div className="flex items-center justify-between">
                    <span className="text-neutral-99 font-semibold">
                      Subtotal{" "}
                      <span className="font-normal">(incl. impuestos)</span>
                    </span>
                    <span className="text-large-semi">
                      {formatAmount({
                        amount: cart.subtotal || 0,
                        region: cart.region,
                        includeTaxes: false,
                        maximumFractionDigits: 0,
                      })}
                    </span>
                  </div>
                  <Link href="/cart" passHref>
                    <Button>Ver el carrito</Button>
                  </Link>
                </div>
              </>
            ) : (
              <div>
                <div className="flex py-16 flex-col gap-y-4 items-center justify-center ">
                  <span>No has agregado productos al carrito.</span>
                  <div>
                    <Link href="/store">
                      <span className="sr-only">Ir a toda la tienda</span>
                      <Button onClick={close}>
                        Explorar productos
                      </Button>
                    </Link>
                  </div>
                </div>
              </div>
            )}
          </Popover.Panel>
        </Transition>
      </Popover>
    </div>
  )
}

export default CartDropdown
