import React from "react"
import { IconProps } from "types/icon"

const AMNLogo: React.FC<IconProps & { secondaryColor?: string }> = ({
  color = "currentColor",
  size = 200,
  secondaryColor,
  ...attributes
}) => {
  return (
    <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
      viewBox="0 0 285.5 85.1"
      enableBackground="new 0 0 285.5 85.1"
      xmlSpace="preserve"
      width={size}
      fill={color}
      {...attributes}
    >
      <g>
        <g>
          <g>
            <g>
              <path className={secondaryColor} d="M97.9,54.9h-4.7l-2.1-4.2l-5.1-10l-5.1,10l-2.1,4.2h-4.7l2.1-4.2l9.7-19.1l9.7,19.1L97.9,54.9z" />
              <path className={secondaryColor} d="M123.2,31.7v23.3H119V40.1l-4.1,3.1l-3.4,2.6l-3.4-2.6l-4.1-3.1v14.8h-4.2V31.7l4.2,3.2l7.5,5.8l7.5-5.8
					L123.2,31.7z"/>
              <path className={secondaryColor} d="M149.8,31.7V55l-4.2-3.2l-15-11.5V55h-4.2V31.8l4.2,3.2l15,11.5V31.7L149.8,31.7z" />
              <path d="M172.3,33.6c1.1,1.1,1.8,2.7,1.8,4.4c0,2.5-1.5,4.7-3.7,5.7c-0.6,0.3-1.2,0.4-1.8,0.5c-0.1,0-0.1,0-0.2,0v0
					h-11.1v10.7h-4.2V31.7h14.7C169.5,31.7,171.1,32.4,172.3,33.6L172.3,33.6z M168.7,39.9c0.8-0.3,1.3-1.1,1.3-1.9
					c0-0.8-0.4-1.3-0.6-1.5c-0.3-0.3-0.8-0.6-1.5-0.6h-10.5v4.3h10.5C168.1,40.1,168.4,40.1,168.7,39.9L168.7,39.9z"/>
              <path d="M200.7,31.7v23.3h-4.2V44.3h-15v10.7h-4.2V31.7h4.2v8.4h15v-8.4H200.7z" />
              <path d="M226.4,43.3c0,6.4-5.2,11.6-11.6,11.6c-6.4,0-11.6-5.2-11.6-11.6c0-6.4,5.2-11.6,11.6-11.6
					C221.2,31.7,226.4,36.9,226.4,43.3z M222.3,43.3c0-4.1-3.4-7.5-7.5-7.5c-4.1,0-7.5,3.4-7.5,7.5c0,4.1,3.4,7.5,7.5,7.5
					C218.9,50.8,222.3,47.4,222.3,43.3z"/>
              <path d="M252.2,31.7V55l-4.2-3.2l-15-11.5V55h-4.2V31.8l4.2,3.2l15,11.5V31.7L252.2,31.7z" />
              <path d="M259.7,37.9v2.2h15v4.2h-15v4.4c0,1.2,1,2.1,2.1,2.1h17v4.2h-17c-3.5,0-6.2-2.8-6.2-6.3V37.9
					c0-3.5,2.8-6.3,6.2-6.3h17v4.2h-17C260.6,35.8,259.7,36.8,259.7,37.9z"/>
            </g>
          </g>
          <g className={secondaryColor}>
            <path d="M26.1,33.4L26.1,33.4L24,41c0,0,8.8,4.8,28.6,3.2l2.2-7.5c0,0,0,0,0.1,0c5.9,1.3,10.6,2.9,13.3,4.5
				c-2.4-2.5-6.9-4.8-12.7-6.6l2.8-9.6c0.7-2.4-0.7-4.9-3.1-5.6L36.4,14c-2.4-0.7-4.9,0.7-5.6,3.1l-4,13.9
				c-7.9,0.3-14.2,1.8-17.5,4.1C12.6,33.9,18.6,33.3,26.1,33.4z M39.5,19.6c0.1-0.4,0.4-0.8,0.8-1c0.4-0.2,0.8-0.3,1.2-0.1l6.8,2
				c0.8,0.2,1.3,1.1,1.1,2c-0.1,0.4-0.4,0.8-0.8,1c-0.4,0.2-0.8,0.3-1.2,0.1l-6.8-2C39.8,21.4,39.3,20.5,39.5,19.6z"/>
            <path d="M51.5,48.2c-4.2-0.1-9-0.3-13.9-0.9c-5.4-0.6-10.4-1.4-14.8-2.4c-8.5-1.9-14.6-4.3-16-6.5c0,0,0,0.1,0,0.1
				c-0.6,3.9,5.4,8.1,14.7,10.9l-3.1,10.6c-0.7,2.4,0.7,4.9,3.1,5.6l18.7,5.4c2.4,0.7,4.9-0.7,5.6-3.1l3-10.2l-9.1-5l0.3,0h0
				c0,0,0,0,0,0l0,0c0.2,0,0.4,0,0.7,0c2.2,0.2,4.4,0.2,6.4,0.2c0.7,0,1.4,0,2,0l-0.1,0c0,0,0,0,0.1,0h0l1,0l0-0.1
				c11.2-0.5,19.3-3.3,19.9-7.6c0,0,0-0.1,0-0.1C67.7,47.3,60.7,48.3,51.5,48.2z"/>
          </g>
        </g>
      </g>
    </svg>
  )
}

export default AMNLogo
