import clsx from "clsx"
import { useCollections } from "medusa-react"
import Link from "next/link"
import CountrySelect from "../country-select"
import { BsFacebook, BsInstagram } from "react-icons/bs"
import AMNLogo from "@modules/common/icons/amnLogo"

const FooterNav = () => {
  const { collections } = useCollections()

  return (
    <div className="content-around flex flex-col gap-y-8 pt-8 pb-8 bg-background-20 text-neutral-90">
      <div className="flex flex-col gap-y-6 text-center">
        <div>
          <Link href="/" className="text-xl-semi flex justify-center">
            <AMNLogo />
          </Link>
        </div>
        <div className="flex flex-col gap-y-6 text-center">
          <div className="flex flex-col gap-48  ">
            <ul>
              <Link href="/policy" className="text-base-semi font-normal ">
                Política de Garantía |
              </Link>
              <Link href="/us" className="text-base-semi font-normal">
                Nosotros |
              </Link>
              <Link href="/returns" className="text-base-semi font-normal">
                Devoluciones y reembolsos |
              </Link>
              <Link href="/legalAdvice" className="text-base-semi font-normal">
                Aviso legal
              </Link>
            </ul>
            {/* <ul
              className={clsx("grid grid-cols-1 gap-y-2", {
                "grid-cols-2": (collections?.length || 0) > 4,
              })}
            >
              {collections?.map((c) => (
                <li key={c.id}>
                  <Link href={`/collections/${c.id}`}>
                    <a>{c.title}</a>
                  </Link>
                </li>
              ))}
            </ul> */}
          </div>
          <div className="gap-y-6">
            <div>
              <span className="text-base-semi gap-y-6 ">
                Síguenos en nuestras redes
              </span>
              <div className="place-content-center flex flex-row justify-content: space-around gap-x-4 pt-2">
                <ul>
                  <li>
                    <a href="https://www.facebook.com/amnphone">
                      <BsFacebook style={{ fontSize: "35px" }} />
                    </a>
                  </li>
                </ul>
                <ul>
                  <li>
                    <a href="https://instagram.com/amn_phone">
                      <BsInstagram style={{ fontSize: "35px" }} />
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="flex flex-col-reverse gap-y-4 justify-center xsmall:items-center xsmall:flex-row xsmall:items-end xsmall:justify-between pr-4 pl-4 pt-2">
              <span className="text-xsmall-regular text-neutral-90">
                Hecho por CCT Company
              </span>
              <div className="min-w-[316px] flex xsmall:justify-end justify-center">
                <CountrySelect />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default FooterNav
