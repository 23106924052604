import { useMobileMenu } from "@lib/context/mobile-menu-context"
import { useStore } from "@lib/context/store-context"
import useCountryOptions from "@lib/hooks/use-country-options"
import AMNLogo from "@modules/common/icons/amnLogo"
import ChevronDown from "@modules/common/icons/chevron-down"
import Search from "@modules/common/icons/search"
import X from "@modules/common/icons/x"
import { useCollections, useMeCustomer } from "medusa-react"
import Link from "next/link"
import ReactCountryFlag from "react-country-flag"
import { MdPowerInput } from "react-icons/md"

const MainMenu = () => {
  const { collections } = useCollections()
  // const { customer } = useMeCustomer()
  const { countryCode } = useStore()

  const countries = useCountryOptions()

  const {
    close,
    screen: [_, setScreen],
  } = useMobileMenu()

  const setScreenCountry = () => setScreen("country")
  const setScreenSearch = () => setScreen("search")

  return (
    <div className="flex flex-col flex-1">
      <div className="flex items-center justify-between w-full border-b border-neutral-90 border-opacity-20 py-4 px-6">
        <div className="flex-1 basis-0">
          <button
            className="flex items-center gap-x-2 "
            onClick={setScreenCountry}
          >
            <ReactCountryFlag countryCode={countryCode || "us"} svg />
            <ChevronDown />
          </button>
        </div>
        <div>
          <h1 className="text-xl-semi uppercase">
            <AMNLogo />
          </h1>
        </div>
        <div className="flex-1 basis-0 flex justify-end">
          <button onClick={close}>
            <X size={20} />
          </button>
        </div>
      </div>

      <div className="space-y-6 flex-1 flex flex-col justify-between p-6 ">
        {process.env.FEATURE_SEARCH_ENABLED && (
          <button
            className="bg-neutral-40 flex items-center px-4 py-2 gap-x-2 text-neutral-90"
            onClick={setScreenSearch}
          >
            <Search size={24} />
            <span placeholder="Search products" className="text-base-regular">
              Search products
            </span>
          </button>
        )}

        <div className="flex flex-col flex-1 text-large-regular">
          <ul className="flex flex-col gap-y-2">
            <li className="bg-transparent bg-background-20 rounded-lg p-4">
              <Link href="/store">
                <button
                  className="flex items-center justify-between w-full "
                  onClick={close}
                >
                  <span className="sr-only">Ve a la Tienda</span>
                  <span>Todos los productos</span>
                  <ChevronDown className="-rotate-90" />
                </button>
              </Link>
            </li>
            {collections ? (
              <>
                {collections.map((collection) => (
                  <li key={collection.id} className="p-4">
                    <Link href={`/collections/${collection.id}`}>
                      <button
                        className="flex items-center justify-between w-full"
                        onClick={close}
                      >
                        <span className="sr-only">
                          Ir a {collection.title}
                        </span>
                        <MdPowerInput />
                        <span>
                          {collection.title}
                        </span>
                        <ChevronDown className="-rotate-90" />
                      </button>
                    </Link>
                  </li>
                ))}
              </>
            ) : null}
          </ul>
        </div>

        {/* <div className="flex flex-col">
          <div className="flex flex-col gap-y-8 text-small-regular">
            {!customer ? (
              <div className="flex flex-col gap-y-4">
                <span className="text-neutral-90 uppercase">Account</span>
                <Link href={`/account/login`} passHref>
                  <button
                    className="flex items-center justify-between border-b border-neutral-90 border-opacity-20 py-2 w-full"
                    onClick={close}
                  >
                    <span className="sr-only">Go to sign in page</span>
                    <span className="normal-case text-tertiary-80">
                      Sign in
                    </span>
                    <ChevronDown className="-rotate-90 text-tertiary-80" />
                  </button>
                </Link>
              </div>
            ) : (
              <div className="flex flex-col gap-y-4">
                <span className="text-tertiary-80 uppercase">Signed in as</span>
                <Link href={`/account`} passHref>
                  <button
                    className="flex items-center justify-between border-b border-neutral-90 border-opacity-20 py-2 w-full"
                    onClick={close}
                  >
                    <span className="sr-only">Go to account page</span>
                    <span className="normal-case">{customer.email}</span>
                    <ChevronDown className="-rotate-90" />
                  </button>
                </Link>
              </div>
            )}
            <div className="flex flex-col gap-y-4">
              <span className="text-neutral-90 uppercase">Delivery</span>
              <button
                className="flex items-center justify-between border-b border-neutral-90 border-opacity-20 py-2"
                onClick={setScreenCountry}
              >
                <span className="sr-only">
                  Click to select shipping country
                </span>
                <div className="flex items-center gap-x-2 text-tertiary-80">
                  <ReactCountryFlag countryCode={countryCode || "us"} svg />
                  <span className="normal-case">
                    Shipping to{" "}
                    {countries?.find((c) => c.country === countryCode)?.label}
                  </span>
                </div>
                <ChevronDown className="-rotate-90 text-tertiary-80" />
              </button>
            </div>
          </div>
        </div> */}
      </div>
    </div>
  )
}

export default MainMenu
